const body = document.body;
const triggerMenu = document.querySelector("header .trigger-menu");
const nav = document.querySelector("header nav");
const menu = document.querySelector("header .menu-menu-nav-container");
const menuItemsHasChildren = document.querySelectorAll('header .menu-item-has-children');
const menuItemLinks = document.querySelectorAll('header .menu-item-has-children a');
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
let lastScroll = 0;

triggerMenu.addEventListener("click", () => {
  body.classList.toggle("menu-open");
  body.classList.remove('submenu-open');
  menuItemsHasChildren.forEach(menuItem => {
    menuItem.classList.remove('open');
  });
});

menuItemLinks.forEach(menuLink => {
  menuLink.addEventListener('click', (event) => {
    event.stopPropagation();
  });
});

menuItemsHasChildren.forEach(menuItem => {
  menuItem.addEventListener('click', () => {
    menuItem.classList.toggle('open');
    body.classList.toggle('submenu-open');
  });
});

window.addEventListener("scroll", () => {
  const currentScroll = window.pageYOffset;
  if (currentScroll < 10) {
    body.classList.remove(scrollUp);
    return;
  }
  
  if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
    // down
    body.classList.remove(scrollUp);
    body.classList.add(scrollDown);
  } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
    // up
    body.classList.remove(scrollDown);
    body.classList.add(scrollUp);
  }
  lastScroll = currentScroll;
});

